var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"purchase-coupon-banner__count-down-widget",class:{
    'homepage-scene': _vm.usingScene === 'homepage',
    'list-scene': _vm.usingScene === 'list',
  },style:({
    color: _vm.metaData?.benefitTextColor ? _vm.hexToRGBA(_vm.metaData?.benefitTextColor, 0.8) : ''
  })},[_vm._m(0),_vm._v(" "),_c('div',{staticClass:"purchase-coupon-banner__count-down-widget-unit",style:({
      background: _vm.metaData?.benefitTextColor ? _vm.hexToRGBA(_vm.metaData?.benefitTextColor, 0.8) : '',
      color: _vm.metaData?.backgroundColor
    })},[_vm._v("\n    "+_vm._s(_vm.expiresIn[0])+"\n  ")]),_vm._v(" "),_c('span',[_vm._v(":")]),_vm._v(" "),_c('div',{staticClass:"purchase-coupon-banner__count-down-widget-unit",style:({
      background: _vm.metaData?.benefitTextColor ? _vm.hexToRGBA(_vm.metaData?.benefitTextColor, 0.8) : '',
      color: _vm.metaData?.backgroundColor
    })},[_vm._v("\n    "+_vm._s(_vm.expiresIn[1])+"\n  ")]),_vm._v(" "),_c('span',[_vm._v(":")]),_vm._v(" "),_c('div',{staticClass:"purchase-coupon-banner__count-down-widget-unit",style:({
      background: _vm.metaData?.benefitTextColor ? _vm.hexToRGBA(_vm.metaData?.benefitTextColor, 0.8) : '',
      color: _vm.metaData?.backgroundColor,
    })},[_vm._v("\n    "+_vm._s(_vm.expiresIn[2])+"\n  ")])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"purchase-coupon-banner__count-down-widget-label"},[_c('span',[_vm._v("Expires in")])])
}]

export { render, staticRenderFns }