import { ref, computed, onUnmounted, nextTick, watch, onMounted } from 'vue'
import { template } from '@shein/common-function'

/**
 * @typedef {import('./store').PurchaseCouponInfo} PurchaseCouponInfo
 * @typedef {import('./store').PurchaseCouponBannerUsingScene} PurchaseCouponBannerUsingScene
 * @typedef {{
 *   couponTitle: string;
 *   couponSubTitle: string;
 *   currentStatus: string;
 *   tipsContent: string;
 *   clickUrl: string;
 * }} CouponDisplayInfo
 */

/** 
 * 计算剩余时间
 * @param {import('vue').Ref<PurchaseCouponInfo>} purchaseCouponInfo
 * @returns {import('vue').ComputedRef<[string, string, string] | null>}
 */
function getExpiresIn(purchaseCouponInfo) {
  const { couponEndTime: couponEndTimeStampString } = purchaseCouponInfo.value
  const endTime = Number(couponEndTimeStampString) * 1e3 // 秒转毫秒
  const countDownMs = ref(Math.max(0, endTime - Date.now()))
  const intervalSeed = setInterval(() => {
    countDownMs.value = Math.max(0, endTime - Date.now())
    if (countDownMs.value <= 0) {
      clearInterval(intervalSeed)
    }
  }, 500)
  onUnmounted(() => {
    clearInterval(intervalSeed)
  })

  // 将剩余时间拆解为时、分、秒
  const expiresIn = computed(() => {
    if (countDownMs.value <= 0) {
      return null
    }

    const totalSeconds = Math.floor(countDownMs.value / 1000)
    const hours = String(Math.floor(totalSeconds / 3600)).padStart(2, '0')
    const minutes = String(Math.floor((totalSeconds % 3600) / 60)).padStart(2, '0')
    const seconds = String(Math.floor(totalSeconds % 60)).padStart(2, '0')
    return [hours, minutes, seconds]
  })
    
  return expiresIn
}

function wrapTextToHtml(text, { className = '', style = '' }, {
  tag = 'span',
} = {}) {
  return `<${tag} class="${className}" style="${style}">${text}</${tag}>`
}

/**
 * 
 * @param {import('vue').Ref<PurchaseCouponBannerUsingScene>} usingScene 
 * @param {import('vue').Ref<PurchaseCouponInfo>} purchaseCouponInfo
 * @param {import('vue').Ref<Record<string, string>>} languages
 */
export function getViewStates(
  usingScene,
  purchaseCouponInfo,
  languages,
) {
  const isHomePageScene = computed(() => usingScene.value === 'homepage')
  const isListPageScene = computed(() => usingScene.value === 'list')

  // 右侧动作按钮文案
  const actionBtnText = computed(() => (
    isAtTopLevelCoupon.value
      ? languages.value.SHEIN_KEY_PWA_33258 // Checkout
      : languages.value.SHEIN_KEY_PWA_33257 // Add
  ))
  const countDownLabel = languages.value.SHEIN_KEY_PWA_33259
  const tipsContent = languages.value.SHEIN_KEY_PWA_33260

  // 是否凑到最终门槛状态
  const isAtTopLevelCoupon = ref(false)
  
  // 计算优惠券剩余时间
  const expiresIn = getExpiresIn(purchaseCouponInfo)

  // 进度条是否展示
  const isShowProgress = ref(true)

  // Tips Popover 展示状态
  const isTipsPopoverShow = ref(false)
  const tipsPopoverPropsStyle = {
    minWidth: '151px',
    maxWidth: '233px',
    fontFamily: 'SF UI Text',
    fontSize: '12px',
  }
  // 滚动页面时关闭 Tips Popover
  if (typeof window !== 'undefined') {
    const handleScroll = () => {
      isTipsPopoverShow.value = false
    }
    window.addEventListener('scroll', handleScroll)
    onUnmounted(() => {
      window.removeEventListener('scroll', handleScroll)
    })
  }

  // 视图展示信息
  const displayInfo = computed(() => {
    const {
      title: couponTitle = '',
      subTitle: couponSubTitle = '',
      addItemState = '1',
      couponMinThreshold = '',
      couponDiscountAmount = '',
      thresholdDifference = '',
      couponSavedAmount = '',
      progressRatio,
      benefitTextHighlightColor = '#FA6338', // 利益点文案突出颜色
    } = purchaseCouponInfo.value

    const progress = (
      progressRatio 
        ? Number(progressRatio) * 100
        : 0
    )

    // 组装当前状态文案
    let currentStatus = ''

    // 更新是否展示进度条
    isShowProgress.value = addItemState !== '4' // 凑满不展示进度条
    isAtTopLevelCoupon.value = addItemState === '4'

    switch (addItemState) {
      case '1':
        // Add {0} to save {1}
        currentStatus = template(
          wrapTextToHtml(couponMinThreshold, { className: 'coupon-min-threshold' }),
          wrapTextToHtml(couponDiscountAmount, { className: 'coupon-discount-amount', style: `color: ${ benefitTextHighlightColor }` }),
          languages.value.SHEIN_KEY_PWA_33252,
        )
        break
      case '2':
        // Add {0} more to save {1}
        currentStatus = template(
          wrapTextToHtml(
            thresholdDifference,
            { className: 'coupon-threshold-difference' },
          ),
          wrapTextToHtml(
            couponDiscountAmount,
            { className: 'coupon-discount-amount', style: `color: ${ benefitTextHighlightColor }` },
          ),
          languages.value.SHEIN_KEY_PWA_33253,
        )
        break
      case '3':
        // Save {0}, add {1} more to save {2}
        currentStatus = template(
          wrapTextToHtml(couponSavedAmount, { className: 'coupon-saved-amount',  style: `color: ${ benefitTextHighlightColor }` }),
          wrapTextToHtml(
            thresholdDifference,
            { className: 'coupon-threshold-difference' },
          ),
          wrapTextToHtml(couponDiscountAmount, { className: 'coupon-discount-amount', style: `color: ${ benefitTextHighlightColor }` }),
          languages.value.SHEIN_KEY_PWA_33254,
        )
        break
      case '4':
        // Eligible to save {0}
        currentStatus = template(
          wrapTextToHtml(couponDiscountAmount, { className: 'coupon-saved-amount', style: `color: ${ benefitTextHighlightColor }` }),
          languages.value.SHEIN_KEY_PWA_33256,
        )
        break
      default:
        // PASS
        break
    }

    return {
      tipsContent,

      couponTitle,
      couponSubTitle,
      currentStatus,
      progress,
      countDownLabel,

      // 测试数据
      // couponTitle: '10% OFF',
      // couponTitle: 'Rp10,000 OFF',
      // couponSubTitle: 'Orders Rp10,000+',
      // currentStatus: 'Saved <span class="coupon-saved-amount">Rp1.500</span>，Add <span class="coupon-threshold-difference">Rp85.100 more</span> to get <span class="coupon-discount-amount">Rp8.000 OFF</span>',
      // progress: 50,
    }
  })

  // 是否展示按钮文字 label
  const isActionLabelShow = ref(true)
  /** @type {import('vue').Ref<HTMLElement | null>} */
  const purchaseCouponBannerInfoTextRef = ref(null)
  /** @param {HTMLElement} target */
  const updateActionLabelShow = async (target) => {
    if (!target) return 
    // 先默认让按钮文字展示，看看状态信息文字会否溢出
    isActionLabelShow.value = true
    await nextTick()
    const isOverflow = target.scrollHeight >= target.clientHeight * 2
    isActionLabelShow.value = !isOverflow
  }
  watch(
    purchaseCouponInfo,
    (newInfo, oldInfo) => {
      if (newInfo.hashCode === oldInfo.hashCode) return
      // 数据变化时也要重新计算是否展示按钮文字 label
      updateActionLabelShow(purchaseCouponBannerInfoTextRef.value)
    }
  )

  // 是否展示倒计时
  const isCouponCountdownShow = computed(() => {
    if (!expiresIn.value) return false

    const [hour] = expiresIn.value
    return (
      isAtTopLevelCoupon.value
      && Number(hour) < 24
    )
  })

  // Tips Popover placement 展示方向
  const tipsPopoverPlacement = ref('bottom') // 默认展示在下方
  const purchaseCouponBannerViewRef = ref(null)
  // 如果 view 容器滚动超过了当前屏幕 50%，则展示在上方
  const getTipsPlacement = () => {
    if (!purchaseCouponBannerViewRef.value) return

    const { top, bottom } = purchaseCouponBannerViewRef.value.getBoundingClientRect()
    const { innerHeight } = window
    if (top < innerHeight / 2) {
      tipsPopoverPlacement.value = 'bottom'
    } else if (bottom > innerHeight / 2) {
      tipsPopoverPlacement.value = 'top'
    }
  }
  if (typeof window !== 'undefined') {
    window.addEventListener('scroll', getTipsPlacement)
    onUnmounted(() => {
      window.removeEventListener('scroll', getTipsPlacement)
    })
  }
  onMounted(() => {
    getTipsPlacement()
  })

  // 列表场景下，当前状态文字信息换行时，字号要设为 10px
  /** @param {HTMLElement} el */
  const mayResetInfoTextFontSizeForListScene = (el, params) => {
    const { oneLineHeight, forCount = 1 } = params
    const renderHeight = el.clientHeight
    if (renderHeight >= oneLineHeight * forCount) {
      el.style.fontSize = '10px'
    }
  }

  return {
    purchaseCouponBannerViewRef,
    purchaseCouponBannerInfoTextRef,
    expiresIn,
    displayInfo,
    actionBtnText,
    tipsPopoverPropsStyle,
    tipsPopoverPlacement,
    isHomePageScene,
    isListPageScene,
    isShowProgress,
    isAtTopLevelCoupon,
    isTipsPopoverShow,
    isActionLabelShow,
    isCouponCountdownShow,
    updateActionLabelShow,
    mayResetInfoTextFontSizeForListScene,
  }
}
