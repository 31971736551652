export default (config) => {
  const { display_type = 1, autoCartData, props } = config
  switch (display_type) {
    case 1: {
      import('public/src/pages/common/add-on/index.js').then((VueComp) => {
        VueComp.default.open(config)
      })
      break
    }
    case 2: {
      const { langPath } = gbCommonInfo

      if (window._gb_app_) {
        const query = {
          props: JSON.stringify(props),
        }
        if (autoCartData) query.autoCartData = 1
        window._gb_app_.$router.push({
          name: 'cartAddOnItem',
          query,
        })
      } else {
        let href = `${langPath}/cart/add_on_item?props=${JSON.stringify(props)}`
        if (autoCartData) href += '&autoCartData=1'
        window.location.href = href
      }
      break
    }
    default:
      console.error('display_type 参数错误')
      break
  }
}
