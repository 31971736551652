import jumpCartAddOnItem from 'public/src/pages/cart_add_on_item/utils/jumpCartAddOnItem.js'

/**
 * @typedef {import('./types').PurchaseCouponInfo} PurchaseCouponInfo
 * @typedef {import('./types').PurchaseCouponBannerUsingScene} PurchaseCouponBannerUsingScene
 */

/**
 * @param {PurchaseCouponInfo} purchaseCouponInfo
 * @param {boolean} isAtTopLevelCoupon
 * @param {(eventName: string, ...args: any[]) => void} vueEmit
 */
function handleClickForHomepage(
  purchaseCouponInfo,
  isAtTopLevelCoupon,
  vueEmit,
) {
  if (isAtTopLevelCoupon) {
    // 跳转购车页
    vueEmit?.(
      'cart-checkout',
      purchaseCouponInfo
    )
  } else {
    // 拉起凑单页
    jumpCartAddOnItem({
      display_type: 2, // 1: 弹窗  2: 页面
      props: {
        type: 'coupon',
        coupon: purchaseCouponInfo.couponCode,
        saInfo: {
          activity_from: 'homepage_banner',
          state: 'homepage_banner',
        }
      }
    })
  }
}

/**
 * @param {PurchaseCouponInfo} purchaseCouponInfo
 */
function handleClickForList(purchaseCouponInfo, isAtTopLevelCoupon, vueEmit, callback) {
  if (isAtTopLevelCoupon) {
    // 跳转购车页
    callback ? callback() : vueEmit?.(
      'cart-checkout',
      purchaseCouponInfo
    )
  } else {
    jumpCartAddOnItem({
      display_type: 1, // 1: 弹窗  2: 页面
      props: {
        type: 'coupon',
        coupon: purchaseCouponInfo.couponCode,
        queryInfo: {
          addOnType: 2, // 0：活动免邮、1：政策免邮、2：促销活动、3：首单免邮、4、阶梯运费
          sceneId: 152,
        },
        saInfo: {
          activity_from: 'list_coupon_add',
          state: 'list_coupon_add',
        },
        drawerConfig: {
          size: '90%',
          maxSize: '90%',
        }
      }
    })
  }
}

/**
 * @param {{
 *   usingScene: PurchaseCouponBannerUsingScene;
 *   purchaseCouponInfo: PurchaseCouponInfo;
 *   isAtTopLevelCoupon: boolean;
 *   vueEmit: (eventName: string, ...args: any[]) => void;
 * }} param
 */
export function handleClickPurchaseCouponBanner({
  usingScene,
  purchaseCouponInfo,
  isAtTopLevelCoupon,
  vueEmit,
  callback
}) {
  if (usingScene === 'homepage') {
    handleClickForHomepage(
      purchaseCouponInfo,
      isAtTopLevelCoupon,
      vueEmit,
    )
  } else {
    handleClickForList(
      purchaseCouponInfo, 
      isAtTopLevelCoupon,
      vueEmit,
      callback
    )
  }
}
