/* eslint-disable no-console */
import { ref, computed, toRefs, onActivated, onMounted, nextTick, watch } from 'vue'
import { handleClickPurchaseCouponBanner } from './handle-click'
import { getViewStates } from './view-state'
import { cccxEventBus } from 'public/src/pages/components/ccc/common/utils.js'
import { debounce } from '@shein/common-function'
import { useHomeSlsLog } from '@modules/homepage/composables/use-home-sls-log.js'
import { isLogin } from 'public/src/pages/common/utils/index.js'
import schttp from 'public/src/services/schttp'
import { useCommonMixin } from '../../common/use-common-mixin'
import { deriveFromGlobalCommonInfo } from '@modules/homepage/utils/derive-from-gb-common-info'
import { hexToRGBA } from 'public/src/pages/components/ccc/common/utils.js'

/**
 * @typedef {import('./types').PurchaseCouponInfo} PurchaseCouponInfo
 * @typedef {import('./types').PurchaseCouponBannerUsingScene} PurchaseCouponBannerUsingScene
 */

/**
 * @param {any} vueProps 
 * @returns {{
 *   purchaseCouponInfo: import('vue').Ref<Partial<PurchaseCouponInfo>>;
 *   languages: import('vue').Ref<Record<string, string>;
 *   vuePropsPropData: import('vue').Ref<Record<string, any>>;
 *   vuePropsSceneData: import('vue').Ref<Record<string, any>>;
 *   vuePropsIndex: import('vue').Ref<number>;
 * }}
 */
function derieveCouponInfoFromVueProps(vueProps) {
  const {
    propData: vuePropsPropData,
    sceneData: vuePropsSceneData,
    index: vuePropsIndex,
    language,
  } = toRefs(vueProps)
  const markMap = computed(() => (vuePropsPropData.value.props?.markMap || {}))
  const languages = computed(() => {
    return language.value?.purchaseCouponBanner || {}
  })
  const purchaseCouponInfo = ref((vuePropsPropData.value.props?.metaData || {}))

  return {
    vuePropsPropData,
    vuePropsSceneData,
    vuePropsIndex,
    purchaseCouponInfo,
    languages,
    markMap,
  }
}

/**
 * @param {import('vue').Ref<Partial<PurchaseCouponInfo>>} purchaseCouponInfo 
 * @param {{
 *   isInitMounted: import('vue').Ref<boolean>;
 *   isLoadingData: import('vue').Ref<boolean>;
 *   isFirstLoaded: import('vue').Ref<boolean>;
 * }} ctx
 */
async function handlePurchaseCouponUpdate(
  purchaseCouponInfo,
  {
    isInitMounted,
    isLoadingData,
    isFirstLoaded,
    vueCompContext,
  }
) {
  if (!isInitMounted.value) {
    // 首次加载时不需要更新数据
    return
  }
  if (isLoadingData.value) {
    // 正在加载数据时不需要再次请求
    return
  } else {
    isLoadingData.value = true
  }

  getPurchaseCouponBannerBffData({ pageFrom: 'homePage' })
    .then(({ code, info }) => {
      if (code !== '0' || !info) {
        return
      }

      if (!isFirstLoaded.value) {
        isFirstLoaded.value = true
        // 第一次加载得到数据，上报所得数据与 SSR 直出数据，以便后续分析是否有数据不一致的情况
        useHomeSlsLog(
          {
            logLevel: 2,
            tag: 'web_client_home_warn_total',
            message: 'CCCPurchaseCouponBannerDataDiff',
            reason: { 
              pre: purchaseCouponInfo.value,
              next: info,
            }
          },
          vueCompContext,
        )
      }
  
      // 数据发生变化，才去更新凑单券信息
      if (info.hashCode !== purchaseCouponInfo.value.hashCode) {
        purchaseCouponInfo.value = {
          ...purchaseCouponInfo.value,
          ...info,
        }
      }
    })
    .catch(error => {
      console.warn('[PurchaseCouponBanner] Fetching purchase coupon info failed.', error)
    })
    .finally(() => {
      isLoadingData.value = false
    })
}

async function getPurchaseCouponBannerBffData() {
  if (!isLogin()) {
    // 未登录时不请求数据
    return
  }

  const reqUrl = '/ccc/purchase_coupon/banner/update'
  try {
    const data = await schttp({
      useBffApi: true,
      url: reqUrl,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      },
    })
    return data || {}
  } catch (error) {
    return Promise.resolve({
      code: -1,
      msg: `${reqUrl} Request Error`
    })
  }
}

function getTabPageId() {
  if (typeof window === 'undefined') return ''
  return (
    window.getSaPageInfo?.tab_page_id
    || (  
      window.SaPageInfo 
        ? (SaPageInfo.page_name + SaPageInfo.start_time) 
        : ''
    )
  )
}

export function usePurchaseCouponBannerStore(vueProps) {
  const {
    purchaseCouponInfo,
    languages,
    markMap,
    vuePropsIndex,
    vuePropsPropData,
    vuePropsSceneData,
  } = derieveCouponInfoFromVueProps(vueProps)
  const {
    jumpLink,
    getAnalysisData,
  } = useCommonMixin({
    propData: vuePropsPropData.value,
    sceneData: vuePropsSceneData.value,
    index: vuePropsIndex.value,
  })
  const { langPath } = deriveFromGlobalCommonInfo()

  // 是否已经初次加载
  const isInitMounted = ref(false)
  // 是否正在加载数据
  const isLoadingData = ref(false)
  // 是否第一次加载得到数据
  const isFirstLoaded = ref(false)

  // 是否展示首页凑单券 Banner 组件
  const isShowHomePageBanner = computed(() => {
    return (
      purchaseCouponInfo.value?.couponEmpty === '0'
    )
  })

  // 异步重新获取数据相关：
  const reFetchContext = {
    isInitMounted,
    isLoadingData,
    isFirstLoaded,
    vueCompContext: {
      propData: vuePropsPropData.value,
      sceneData: vuePropsSceneData.value,
      index: vuePropsIndex.value,
    },
  }
  const createUpdateHandler = (triggerBy) => {
    return debounce({
      func: async () => {
        console.log(`[PurchaseCouponBanner] update request triggered by ${triggerBy}`)
        await handlePurchaseCouponUpdate(
          purchaseCouponInfo, 
          reFetchContext,
        )
      },
    })
  }
  const updateForCouponExpired = createUpdateHandler('CouponExpired')
  const updateForOnActivated = createUpdateHandler('OnActivated')
  // 当组件恢复可见时，触发更新
  const onContainerVisible = createUpdateHandler('onContainerVisible')
  
  // 优惠券过期状态发生变化时重新请求数据
  const isExpired = computed(() => {
    const { couponEndTime: couponEndTimeStampString } = purchaseCouponInfo.value
    const endTime = Number(couponEndTimeStampString) * 1e3 // 秒转毫秒
    const countDownMs = Math.max(0, endTime - Date.now())
    
    return countDownMs <= 0
  })
  watch(
    isExpired,
    (nowState, oldState) => {
      if (nowState !== oldState) {
        updateForCouponExpired()
      }
    }
  )

  const formatAnalysisData = (evtKey) => {
    return getAnalysisData(
      evtKey,
      {
        useBffApi: true,
        item: {
          oprt_loc: String(vuePropsIndex.value + 1),
          item_loc: '1',
          markMap: markMap.value,
        },
        tab: [{}], // 保证 tab_list: '1`-`-`-'
        customParams: {
          src_tab_page_id: getTabPageId() || '',
          tab_list: '1`-`-`-',
        }
      }
    )
  }
  const jumpCartCheckout = () => {
    jumpLink({
      url: `${langPath}/cart`,
    })
  }
  
  onMounted(() => {
    isInitMounted.value = true
    nextTick(() => {
      cccxEventBus.on(
        'free-shipping-update-cartinfo', 
        createUpdateHandler('free-shipping-update-cartinfo')
      )
      cccxEventBus.on?.(
        'couponPackage-bindCouponSuccess', 
        createUpdateHandler('couponPackage-bindCouponSuccess'),
      )
      appEventCenter?.$on(
        'homeCccxCouponUpdate',
        createUpdateHandler('homeCccxCouponUpdate'),
      )
    })
  })
  onActivated(async () => {
    await updateForOnActivated()
  })

  return {
    purchaseCouponInfo,
    languages,
    markMap,
    isShowHomePageBanner,
    isLoadingData,
    onContainerVisible,
    formatAnalysisData,
    jumpCartCheckout,
  }
}

export function useViewStore(vueProps, { emit: vueEmit } = {}) {
  const { 
    usingScene, 
    purchaseCouponInfo, 
    languages,
  } = toRefs(vueProps)

  const viewStates = getViewStates(
    usingScene,
    purchaseCouponInfo,
    languages,
  )

  const onClickAction = () => {
    handleClickPurchaseCouponBanner({
      usingScene: usingScene.value,
      purchaseCouponInfo: purchaseCouponInfo.value,
      isAtTopLevelCoupon: viewStates.isAtTopLevelCoupon.value,
      vueEmit,
    })
    vueEmit?.(
      'click-action',
      purchaseCouponInfo.value
    )
  }

  const cssVariables = computed(() => {
    const { borderColor = '', couponBackgroundImage = {} }  = purchaseCouponInfo.value || {}
    if(!borderColor ||  couponBackgroundImage?.src){
      return {}
    }
    return {
      '--border-color': `1px solid ${hexToRGBA(borderColor, 0.3)}`,
    }
  })
  const bgStyle = computed(() => {
    const { backgroundImage = {}, backgroundColor, borderColor }  = purchaseCouponInfo.value || {}
    // 优先取背景图
    if(backgroundImage?.src){
      return {
        backgroundSize: '100% 100%',
        backgroundImage: `url(${backgroundImage.src})`,
        backgroundColor: 'transparent',
      }
    }
    if(backgroundColor){
      return {
        background: backgroundColor,
        border: `1px solid ${hexToRGBA(borderColor, 0.1)}`
      }
    }
    return {}
  })
  const couponBgStyle = computed(() => {
    const { 
      backgroundColorStart = '', 
      backgroundColorMiddle = '', 
      backgroundColorEnd = '', 
      couponBackgroundImage = {},
    }  = purchaseCouponInfo.value || {}
    if(couponBackgroundImage?.src){
      return {
        background: `url(${couponBackgroundImage?.src})`,
        backgroundSize: '100% 100%',
        backgroundClip: 'padding-box',
      }
    }
    return {
      background: `linear-gradient(to right, ${backgroundColorStart || 'transparent'}, ${backgroundColorMiddle || 'transparent'} 50%, ${backgroundColorEnd || 'transparent'})`,
      backgroundClip: 'padding-box',
    }
  })

  return {
    ...viewStates,
    cssVariables,
    bgStyle,
    couponBgStyle,
    onClickAction,
  }
}
