var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:"purchaseCouponBannerViewRef",staticClass:"purchase-coupon-banner__view-container",class:{
    'homepage-scene': _vm.isHomePageScene,
    'list-scene': _vm.isListPageScene,
  }},[_c('div',{staticClass:"purchase-coupon-banner__view-card",style:(_vm.bgStyle)},[_c('div',{staticClass:"purchase-coupon-banner__coupon-face-group"},[_c('div',{staticClass:"purchase-coupon-banner__cover-small",style:([_vm.couponBgStyle,_vm.cssVariables])}),_vm._v(" "),(_vm.purchaseCouponInfo?.benefitTextColor)?_c('div',{staticClass:"purchase-coupon-banner__coupon-bg",style:([_vm.couponBgStyle,_vm.cssVariables])},[_c('div',{staticClass:"purchase-coupon-banner__coupon-face-info"},[_c('div',{staticClass:"purchase-coupon-banner__coupon-discount",style:({
              color: _vm.purchaseCouponInfo?.benefitTextColor
            })},[_c('s-adapter-text',{attrs:{"min-size":10,"lines":_vm.isListPageScene ? 1 : 2,"text":_vm.displayInfo.couponTitle}})],1),_vm._v(" "),_c('s-adapter-text',{staticClass:"purchase-coupon-banner__coupon-threshold",style:({
              color: _vm.purchaseCouponInfo.benefitTextColor
            }),attrs:{"min-size":8,"text":_vm.displayInfo.couponSubTitle}})],1)]):_c('div',{staticClass:"purchase-coupon-banner__coupon-face-info"},[_c('div',{staticClass:"purchase-coupon-banner__coupon-discount",style:({
            color: _vm.purchaseCouponInfo?.benefitTextColor
          })},[_c('s-adapter-text',{attrs:{"min-size":10,"lines":_vm.isListPageScene ? 1 : 2,"text":_vm.displayInfo.couponTitle}})],1),_vm._v(" "),_c('s-adapter-text',{staticClass:"purchase-coupon-banner__coupon-threshold",style:({
            color: _vm.purchaseCouponInfo.benefitTextColor
          }),attrs:{"min-size":8,"text":_vm.displayInfo.couponSubTitle}})],1),_vm._v(" "),(!_vm.purchaseCouponInfo?.borderColor)?_c('PurchaseCouponFace',{staticClass:"purchase-coupon-banner__coupon-face"}):_vm._e()],1),_vm._v(" "),_c('div',{staticClass:"purchase-coupon-banner__current-status-wrapper"},[(_vm.isAtTopLevelCoupon)?_c('SuiIconSelected',{staticClass:"purchase-coupon-banner__current-status-at-end-icon",style:({
          color: _vm.purchaseCouponInfo?.benefitTextColor
        })}):_vm._e(),_vm._v(" "),(_vm.isHomePageScene)?_c('SPopover',{attrs:{"placemen":_vm.tipsPopoverPlacement,"theme":"light","show-close-icon":"","outside-close":"","fix-disappear-position":"","prop-style":_vm.tipsPopoverPropsStyle,"custom-class":`purchase-coupon-banner__current-status-info-tips-popover placement-${_vm.tipsPopoverPlacement}`},model:{value:(_vm.isTipsPopoverShow),callback:function ($$v) {_vm.isTipsPopoverShow=$$v},expression:"isTipsPopoverShow"}},[_c('span',[_vm._v("\n          "+_vm._s(_vm.displayInfo.tipsContent)+"\n        ")]),_vm._v(" "),_c('template',{slot:"reference"},[_c('div',{staticClass:"purchase-coupon-banner__current-status",class:{
              'with-at-end-icon': _vm.isAtTopLevelCoupon,
            }},[_c('div',{staticClass:"purchase-coupon-banner__current-status-info",class:{
                'is-coupon-countdown-show': _vm.isCouponCountdownShow,
              },style:({
                color: _vm.purchaseCouponInfo?.benefitTextColor || '#4B0303',
              })},[_c('div',{staticClass:"purchase-coupon-banner__current-status-info-popover-ref"},[_c('span',{directives:[{name:"on-visible",rawName:"v-on-visible",value:(_vm.updateActionLabelShow),expression:"updateActionLabelShow"}],ref:"purchaseCouponBannerInfoTextRef",staticClass:"purchase-coupon-banner__current-status-info-text",class:{
                    'is-at-top-level': _vm.isAtTopLevelCoupon,
                    'is-count-down-show': _vm.isCouponCountdownShow,
                  },domProps:{"innerHTML":_vm._s(_vm.displayInfo.currentStatus)}}),_vm._v(" "),_c('SuiIconDoubt',{staticClass:"purchase-coupon-banner__current-status-info-tips-icon",style:({
                    color: _vm.purchaseCouponInfo?.describeIconColor || '#BBBBBB',
                  })})],1)]),_vm._v(" "),(_vm.isCouponCountdownShow)?_c('PurchaseCouponBannerCountDown',{attrs:{"usingScene":_vm.usingScene,"countDownLabel":_vm.displayInfo.countDownLabel,"expiresIn":_vm.expiresIn,"meta-data":_vm.purchaseCouponInfo}}):_vm._e(),_vm._v(" "),_c('PurchaseCouponBannerProgress',{attrs:{"isShowProgress":_vm.isShowProgress,"displayInfo":_vm.displayInfo,"meta-data":_vm.purchaseCouponInfo}})],1)])],2):(_vm.isListPageScene)?_c('div',{staticClass:"purchase-coupon-banner__current-status-info"},[_c('div',{staticClass:"purchase-coupon-banner__current-status",class:{
            'with-at-end-icon': _vm.isAtTopLevelCoupon,
          }},[_c('span',{directives:[{name:"on-visible",rawName:"v-on-visible",value:((el) => _vm.mayResetInfoTextFontSizeForListScene(el, {
              oneLineHeight: 13,
              forCount: 2,
            })),expression:"(el) => mayResetInfoTextFontSizeForListScene(el, {\n              oneLineHeight: 13,\n              forCount: 2,\n            })"}],staticClass:"purchase-coupon-banner__current-status-info-text",domProps:{"innerHTML":_vm._s(_vm.displayInfo.currentStatus)}}),_vm._v(" "),_c('PurchaseCouponBannerProgress',{attrs:{"isShowProgress":_vm.isShowProgress,"meta-data":_vm.purchaseCouponInfo,"displayInfo":_vm.displayInfo}}),_vm._v(" "),(_vm.isCouponCountdownShow)?_c('PurchaseCouponBannerCountDown',{attrs:{"usingScene":_vm.usingScene,"countDownLabel":_vm.displayInfo.countDownLabel,"expiresIn":_vm.expiresIn,"meta-data":_vm.purchaseCouponInfo}}):_vm._e()],1)]):_vm._e()],1),_vm._v(" "),_c('div',{staticClass:"purchase-coupon-banner__view-action",style:({
        color: _vm.purchaseCouponInfo.benefitTextColor,
        opacity: _vm.purchaseCouponInfo.benefitTextColor ? 0.6 : 1
      }),on:{"click":_vm.onClickAction}},[_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.isActionLabelShow),expression:"isActionLabelShow"}],staticClass:"purchase-coupon-banner__view-action-text"},[_vm._v(_vm._s(_vm.actionBtnText))]),_vm._v(" "),_c('SuiIconMoreRight',{staticClass:"purchase-coupon-banner__view-action-icon",attrs:{"width":String(_vm.isListPageScene ? 12 : 16),"height":String(_vm.isListPageScene ? 12 : 16)}})],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }