var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.isShowProgress)?_c('div',{staticClass:"purchase-coupon-banner__current-status-progress",style:({
    background: _vm.metaData?.benefitTextHighlightColor ? _setup.hexToRGBA(_vm.metaData?.benefitTextHighlightColor, 0.3) : '',
  })},[_c('div',{staticClass:"purchase-coupon-banner__current-status-progress-bar",style:({
      // 最小也要保留 3% 的进度条
      width: `${_vm.displayInfo.progress || 3}%`,
      background: _vm.metaData?.benefitTextHighlightColor,
    })})]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }