/** @type {import('vue').Directive} */
export const vOnVisible = {
  bind(el, binding) {
    const callback = binding.value
    // 创建一个 IntersectionObserver 监听元素的可见性
    const observer = new IntersectionObserver((entries) => {
      entries.forEach(async (entry) => {
        if (entry.isIntersecting) {
          await callback(entry.target)
        }
      })
    }, {
      threshold: [0] // 当元素进入视口时触发
    })

    // 启动 IntersectionObserver
    observer.observe(el)
    // 在元素上保存 observer 实例，以便在 unbind 时可以断开观察
    el.__visibleObserver__ = observer
  },
  unbind(el) {
    // 断开 IntersectionObserver
    if (el.__visibleObserver__) {
      el.__visibleObserver__.disconnect()
      delete el.__visibleObserver__
    }
  }
}
