export function deriveFromGlobalCommonInfo() {
  const {
    langPath = '',  
    PUBLIC_CDN,
    GB_cssRight: cssRight, 
  } = typeof window !== 'undefined' ? (window.gbCommonInfo ?? {}) : {}

  return {
    langPath,
    PUBLIC_CDN,
    cssRight,
  }
}
