import { render, staticRenderFns } from "./view.vue?vue&type=template&id=b8521940&"
import script from "./view.vue?vue&type=script&lang=js&"
export * from "./view.vue?vue&type=script&lang=js&"
import style0 from "./view.vue?vue&type=style&index=0&id=b8521940&prod&lang=less&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/.pnpm/vue-loader@15.10.0_css-loader@6.8.1_ejs@2.7.2_lodash@4.17.21_vue-template-compiler@2.7.14_webpack@5.86.0/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports